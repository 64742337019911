<template>
    <div class="row">
        <div class="col-12">
            <div class="card card m-b-0">
                <!-- .chat-row -->
                <div class="chat-main-box">
                    <div class="chat-right-aside" style="width:100%">
                        <div class="chat-main-header"></div>
                        <div class="chat-rbox static-content">
                            <div class="wrapp-chat-content" data-scrollbar="true" tabindex="1" style="overflow: hidden; outline: none; height:90vh;">
                                <div class="scroll-content">
                                    <div class="wrapp-dashboard">
                                        Wishlist
                                        <div class="form-group row m-t-20">
                                            <div class="col-sm-12 col-lg-12">
                                                <div class="form-horizontal form-material">
                                                    <div class="wrapp-input-contact min-height">
                                                        <div class="container">
                                                            <div class="row">
                                                                <div class="col col-4">
                                                                    <v-text-field v-model="term" label="Nama / Kode Produk" />
                                                                </div>
                                                                <div class="col col-3">
                                                                    <v-select v-model="selectedNotification"
                                                                        :items="notifications" 
                                                                        item-value="value"
                                                                        item-text="id"
                                                                        label="Notifikasi"
                                                                    ></v-select>
                                                                </div>
                                                                <div class="col col-2 text-right">
                                                                    <v-btn class="btn mt-3" 
                                                                        @click="handleSearch()"
                                                                        :disabled="isLoading">
                                                                        Cari
                                                                    </v-btn>
                                                                </div>
                                                            </div>
                                                            <v-row v-if="wishlists.length > 0 && selectedFields.length > 0 && !isLoading">
                                                                <v-col>
                                                                    <v-menu offset-y>
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-btn color="deep-purple accent-4" dark v-bind="attrs" v-on="on">
                                                                                <span class="text-white">
                                                                                    Kirim Pesan Batch
                                                                                </span>
                                                                            </v-btn>
                                                                        </template>
                                                                        <v-list dense>
                                                                            <v-list-item-group>
                                                                                <v-list-item @click="openPreviewBatch('WA')"> 
                                                                                    <v-list-item-icon>
                                                                                        <v-icon>mdi-whatsapp</v-icon>
                                                                                    </v-list-item-icon>
                                                                                    <v-list-item-content>
                                                                                        <v-list-item-title>WhatsApp</v-list-item-title>
                                                                                    </v-list-item-content>
                                                                                </v-list-item>
                                                                                <v-list-item @click="openPreviewBatch('EMAIL')">
                                                                                    <v-list-item-icon>
                                                                                        <v-icon>mdi-gmail</v-icon>
                                                                                    </v-list-item-icon>
                                                                                    <v-list-item-content>
                                                                                        <v-list-item-title>Email</v-list-item-title>
                                                                                    </v-list-item-content>
                                                                                </v-list-item>
                                                                            </v-list-item-group>
                                                                        </v-list>
                                                                    </v-menu>
                                                                </v-col>
                                                            </v-row>
                                                            <v-progress-linear v-if="isLoading"
                                                                indeterminate 
                                                                color="primary"
                                                            ></v-progress-linear>
                                                            <v-simple-table class="elevation-1 row-top table-striped table-condensed order-report mt-5">
                                                                <template v-slot:default>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>
                                                                                <v-checkbox v-if="wishlists.length > 0"
                                                                                    v-model="checked" 
                                                                                    @click="clickChecked()" />
                                                                            </th>
                                                                            <th>Pelanggan</th>
                                                                            <th class="text-center">Produk</th>
                                                                            <th class="text-center" v-if="selectedFilterNotification == true">Aksi</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-for="wishlist in wishlists" :key="wishlist.id">
                                                                            <td>
                                                                                <v-checkbox v-model="selectedFields" :value="wishlist" />
                                                                            </td>
                                                                            <td>
                                                                                <span>{{ wishlist.personId }}</span>
                                                                                <br />
                                                                                <span>{{ wishlist.personName }}</span>
                                                                            </td>
                                                                            <td class="p-3">
                                                                                <div v-for="(product, index) in wishlist.products" :key="product.wishlistId">
                                                                                    <div class="row align-items-center">
                                                                                        <div class="col col-md-3">
                                                                                            <img :src="product.productThumbUrl" alt="product-wishlist" width="80" />
                                                                                        </div>
                                                                                        <div class="col col-md-9">
                                                                                            <span style="font-weight: bold">{{ product.productName }}</span>
                                                                                            <div class="mb-1 mt-1 text-secondary">
                                                                                                {{ product.productSlug }}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <hr v-if="wishlist.products.length - 1 != index" />
                                                                                </div>
                                                                            </td>
                                                                            <td v-if="selectedFilterNotification == true">
                                                                                <div>
                                                                                    <v-btn class="mx-2" fab dark small color="success" @click="openPreview(wishlist, 'WA')">
                                                                                        <v-icon dark>
                                                                                            mdi-whatsapp
                                                                                        </v-icon>
                                                                                    </v-btn>
                                                                                    <v-btn class="mx-2" fab dark small color="red" @click="openPreview(wishlist, 'EMAIL')">
                                                                                        <v-icon dark>
                                                                                            mdi-gmail
                                                                                        </v-icon>
                                                                                    </v-btn>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </template>
                                                            </v-simple-table>
                                                            <div class="row mt-2 mb-5 text-center" style="color: black !important;" v-if="wishlists.length > 0">
                                                                <div class="col col-12 pagination-address">
                                                                    <v-pagination color circle
                                                                        v-model="number" 
                                                                        :length="totalPages" 
                                                                        :total-visible="5" 
                                                                        prev-icon="mdi-menu-left"
                                                                        next-icon="mdi-menu-right"
                                                                    ></v-pagination>
                                                                    <v-btn color="primary mt-2" @click="showAll()">
                                                                        Tampilkan Semua
                                                                    </v-btn>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="scrollbar-track scrollbar-track-x" style="display: none;">
                                    <div class="scrollbar-thumb scrollbar-thumb-x"
                                        style="width: 661px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                                <div class="scrollbar-track scrollbar-track-y" style="display: block;">
                                    <div class="scrollbar-thumb scrollbar-thumb-y"
                                        style="height: 445.289px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <v-dialog max-width="600" v-model="dialog" persistent>
            <template>
                <v-card>
                    <v-toolbar color="primary" dark>
                        <v-icon class="mr-5">
                            {{ kind == 'WA' ? 'mdi-whatsapp' : 'mdi-gmail' }}
                        </v-icon>
                        <v-toolbar-title>
                            <span>
                                Kirim Pesan Ke {{ kind == 'WA' ? 'WhatsApp' : 'Email' }}
                            </span>
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-row class="mt-2" v-if="isLoadingDialog">
                            <v-col cols="12" class="text-center">
                                <v-progress-circular 
                                    color="primary"
                                    indeterminate
                                ></v-progress-circular>
                            </v-col>
                        </v-row>
                        <v-row class="mt-2" v-else>
                            <v-col cols="12">
                                <v-textarea outlined
                                    label="Pesan"
                                    v-model="text"
                                    rows="8"
                                ></v-textarea>
                            </v-col>
                            <v-col cols="12" v-if="kind == 'WA'">
                                <v-autocomplete
                                    v-model="selectedInputContactId"
                                    class="" :loading="isLoadingContact"
                                    :items="inputContactList"
                                    :search-input.sync="searchInputContact"
                                    :no-filter="true"
                                    label="Kontak"
                                    hide-no-data
                                    item-value="id"
                                    @keyup.enter="searchContact"
                                    :rules="rules.required"
                                    append-outer-icon="*">
                                    <template v-slot:selection="data">
                                        <span class="ml-2">
                                            {{ data.item.name }}
                                        </span>
                                    </template>
                                    <template v-slot:item="data" >
                                        <div class="d-block" style="width:100%">
                                        ({{ data.item.name }})
                                        {{ data.item.primaryPhoneNumber }}
                                        <span class="pull-right text-right">
                                            <v-icon v-if="data.item.contactStatus == 'ACTIVE'" title="Channel Aktif" color="green" small>mdi-check-circle</v-icon>
                                            <v-icon v-if="data.item.contactStatus == 'INACTIVE'" title="Channel Inaktif" color="red" small>mdi-minus-circle</v-icon>
                                        </span>
                                        </div>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" v-if="kind == 'WA'">
                                <v-autocomplete
                                    v-model="selectedInputChannel"
                                    class="" :loading="isLoadingChannel"
                                    :items="inputChannelList"
                                    item-value="_id" :no-filter="true"
                                    hide-no-data
                                    label="Saluran"
                                    :rules="rules.required"
                                    append-outer-icon="*">
                                    <template v-slot:selection="data">
                                        <span class="ml-2">
                                            {{ data.item.name }}
                                        </span>
                                    </template>
                                    <template v-slot:item="data">
                                        <div class="d-block" style="width:100%">
                                            {{ data.item.name }}
                                            <span class="pull-right text-right">
                                                <v-icon v-if="data.item.status == 'ACTIVE'" title="Channel Aktif" color="green" small>mdi-check-circle</v-icon>
                                                <v-icon v-if="data.item.status == 'INACTIVE'" title="Channel Inaktif" color="red" small>mdi-minus-circle</v-icon>
                                            </span>
                                        </div>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" v-if="kind == 'EMAIL'">
                                <v-simple-table class="elevation-1 row-top table-striped table-condensed">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th>Nama</th>
                                                <th>Kontak</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{{ selectedCustomer.name }}</td>
                                                <td>{{ selectedCustomer.primaryEmail }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn @click="dialog = false">
                            BATAL
                        </v-btn>
                        <v-btn @click="sendMessage()" color="primary" :loading="isLoadingSend" v-if="kind == 'WA'">
                            KIRIM
                        </v-btn>
                        <v-btn @click="sendEmail()" color="primary" :loading="isLoadingSend" v-if="kind == 'EMAIL'">
                            KIRIM
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>

        <v-dialog max-width="600" v-model="dialogBatch" persistent>
            <template>
                <v-card>
                    <v-toolbar color="primary" dark>
                        <v-icon class="mr-5">
                            {{ kind == 'WA' ? 'mdi-whatsapp' : 'mdi-gmail' }}
                        </v-icon>
                        <v-toolbar-title>
                            <span>Kirim Pesan Ke {{ kind == 'WA' ? 'WhatsApp ' : 'Email ' }} Batch</span>
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-row class="mt-2" v-if="isLoadingDialog">
                            <v-col cols="12">
                                <v-progress-circular 
                                    color="primary"
                                    indeterminate
                                ></v-progress-circular>
                            </v-col>
                        </v-row>
                        <v-row class="mt-2" v-else>
                            <v-col cols="12">
                                <v-simple-table class="elevation-1 row-top table-striped table-condensed order-report">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <span>ID</span>
                                                    <br />
                                                    <span>Nama</span>
                                                </th>
                                                <th>Kontak</th>
                                                <th class="text-center">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="selected in selectedFields" :key="selected.key">
                                                <td>
                                                    <span>{{ selected.personId }}</span>
                                                    <br />
                                                    <span>{{ selected.personName }}</span>
                                                </td>
                                                <td>
                                                    <div v-if="selected.customer">
                                                        <span>{{ selected.customer.primaryPhoneNumber }}</span>
                                                        <br />
                                                        <span>{{ selected.customer.primaryEmail }}</span>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <v-progress-circular v-if="selected.isLoading == true"
                                                        color="primary"
                                                        indeterminate
                                                    ></v-progress-circular>
                                                    <v-icon color="green" v-else-if="selected.isSuccess == true">
                                                        mdi-check
                                                    </v-icon>
                                                    <v-icon color="red" v-else-if="selected.isSuccess == false">
                                                        mdi-window-close
                                                    </v-icon>
                                                    <span v-else-if="selected.haveNoOrder == true" class="text-success">
                                                        tidak memiliki nota yang belum di bayar
                                                    </span>
                                                </td>
                                                
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                            <v-col cols="12">
                                <v-autocomplete v-if="kind == 'WA'"
                                    v-model="selectedInputChannel"
                                    class="" :loading="isLoadingChannel"
                                    :items="inputChannelList"
                                    item-value="_id" :no-filter="true"
                                    hide-no-data
                                    label="Saluran"
                                    :rules="rules.required"
                                    append-outer-icon="*">
                                    <template v-slot:selection="data">
                                        <span class="ml-2">
                                            {{ data.item.name }}
                                        </span>
                                    </template>
                                    <template v-slot:item="data">
                                        <div class="d-block" style="width:100%">
                                            {{ data.item.name }}
                                            <span class="pull-right text-right">
                                                <v-icon v-if="data.item.status == 'ACTIVE'" title="Channel Aktif" color="green" small>mdi-check-circle</v-icon>
                                                <v-icon v-if="data.item.status == 'INACTIVE'" title="Channel Inaktif" color="red" small>mdi-minus-circle</v-icon>
                                            </span>
                                        </div>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn @click="dialogBatch = false">
                            TUTUP
                        </v-btn>
                        <v-btn @click="sendMessageBatch()" color="primary" :loading="isLoadingSend" v-if="kind == 'WA'">
                            KIRIM
                        </v-btn>
                        <v-btn @click="sendEmailBatch()" color="primary" :loading="isLoadingSend" v-if="kind == 'EMAIL'">
                            KIRIM
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>

        <!-- <SuccessNotif /> -->
    </div>
</template>
<script>
    import axios from 'axios'
    import Scrollbar from 'smooth-scrollbar'
    // import ErrorMessage from '../components/ErrorMessage'
    import MainLayout from "../layouts/MainLayout"
    // import SuccessNotif from "../components/SuccessNotif"
    import {
        mapActions,
        mapState
    } from 'vuex'
    // import countryCodes from "../utils/country-codes"
    // import LineChart from "../components/LineChart"

    const $ = require("jquery")
    // const debounce = require('lodash.debounce')
    import Toast from '../plugins/vuenotification'
    const {
        commonpagesetup,
        mainuisetup
    } = require('../utils/ui-setup')

    // const USER_ROW_PER_PAGE = 25
    // var userScrollBar = null

    var moment = require('moment');
    const uuidv4 = require('uuid/v4');
    
    export default {
        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo
            })
        },
        data: function () {
            return {
                number: 1,
                size: 10,
                totalPages: 0,
                totalElements: 0,

                kind: 'WA',

                text: String(),
                term: String(),
                queryId: String(),
                selectedInputContactId: String(),
                searchInputContact: String(),
                selectedInputChannel: String(),
                
                wishlists: Array(),
                inputContactList: Array(),
                inputChannelList: Array(),
                selectedWishlistIds: Array(),
                selectedFields: Array(),
                
                selectedCustomer: Object(),
                
                checked: false,
                selectedNotification: false,
                dialog: false,
                dialogBatch: false,
                isLoading: false,
                isLoadingDialog: false,
                isLoadingContact: false,
                isLoadingChannel: false,
                isLoadingSend: false,

                selectedFilterNotification: false,

                notifications: [
                    { id: "Semua", value: false },
                    { id: "Menunggu Pemberitahuan", value: true }
                ],

                rules: {
                    required: [(v) => !!v || ""],
                    requiredObject: [(v) => Object.keys(v).length > 0 || ""],
                    requiredArray: [(v) => v.length > 0 || ""],
                },
            }
        },
        watch: {
            number() {
				this.getReportWishlist()
			},
            selectedFields(val) {
                if(val.length != 0 && val.length == this.wishlists.length) {
                    this.checked = true
                } else {
                    this.checked = false
                }
            },
        },

        methods: {
            ...mapActions({
                initOperatorList: 'operator/initOperatorList'
            }),

            clickChecked() {
                if(this.checked) {
                    this.selectedFields = this.wishlists
                } else {
                    this.selectedFields = Array()
                }
            },

            handleSearch: async function() {
                await this.postReport()

                this.size = 10
                this.number = 1
                this.selectedFilterNotification = this.selectedNotification
                await this.getReportWishlist()
            },

            showAll: async function() {
                this.size = this.totalElements
                this.number = 1

                await this.getReportWishlist(false)
            },

            formatMessage: function(products) {
                let text = 'Wishlist anda sudah tersedia\n'

                products.forEach(e => {
                    text += `\n${e.productName}\n${process.env.VUE_APP_COMMERCE_WEB_URL}/${e.productSlug}\n`
                })

                text += `\nProduk tersebut sudah dapat dipesan, ayo segera pesan sebelum kehabisan...`

                return text
            },

            openPreview: async function(wishlist, kind) {
                this.isLoadingDialog = true
                this.kind = kind
                this.dialog = true

                this.searchInputContact = wishlist.personName
                this.selectedInputContactId = wishlist.personId

                if(kind == 'WA') {
                    this.searchContact()
                } else {
                    const { data: customer } = await this.getCustomerById(wishlist.personId)
                    const email = customer.emails.find(e => e.primary == true)
                    
                    if(email) customer.primaryEmail = email.email

                    this.selectedCustomer = customer
                }

                this.text = this.formatMessage(wishlist.products)

                this.selectedWishlistIds = wishlist.products.map(e => e.wishlistId)

                this.isLoadingDialog = false
            },

            openPreviewBatch: async function(kind) {
                this.dialogBatch = true
                this.isLoadingDialog = true
                this.kind = kind

                for (let i = 0; i < this.selectedFields.length; i++) {
                    const e = this.selectedFields[i];
                    
                    const { data: customer } = await this.getCustomerById(e.personId)
                    const email = customer.emails.find(e => e.primary == true)
                    
                    if(email) customer.primaryEmail = email.email
                    
                    e.customer = customer
                }

                this.isLoadingDialog = false
            },

            sendMessage: async function() {
                try {
                    this.isLoadingSend = true

                    const customer = this.inputContactList.find(e => e.id == this.selectedInputContactId)
                    const channel = this.inputChannelList.find(e => e._id == this.selectedInputChannel)
                    const defaultChannel = channel.channel_type == 'FBMSGR' ? 'FB' : channel.channel_type == 'INSTAGRAM' ? 'IG' : 'WA'

                    const body = {
                        id: uuidv4(),
                        channelId: this.selectedInputChannel,
                        text: this.text,
                        number: customer.primaryPhoneNumber
                    };

                    const res = await this.postMessage(body, defaultChannel, this.selectedInputContactId)

                    if(res.status == 200) {
                        this.dialog = false
                        Toast.showToast("success", "Berhasil mengirim pesan kepada " + customer.name)
                        this.putNotifWishlists(this.selectedWishlistIds.join(','))
                    }

                    this.isLoadingSend = false
                } catch (error) {
                    this.isLoadingSend = false
                    console.log("send-message-error", error)
                }
            },

            sendEmail: async function() {
                try {
                    this.isLoadingSend = true

                    const customer = this.selectedCustomer

                    const body = {
                        to: this.selectedCustomer.primaryEmail,
                        subject: "Informasi Produk Wishlist",
                        text: this.text
                    }

                    const res = await this.postEmail(body)

                    if(res.status == 200) {
                        this.dialog = false
                        Toast.showToast("success", "Berhasil mengirim email kepada " + customer.name)
                        this.putNotifWishlists(this.selectedWishlistIds.join(','))
                    }

                    this.isLoadingSend = false
                } catch (error) {
                    this.isLoadingSend = false
                    console.log("send-message-error", error)
                }
            },

            sendMessageBatch: async function() {
                this.isLoadingSend = true

                for (let i = 0; i < this.selectedFields.length; i++) {
                    const e = this.selectedFields[i];

                    try {
                        e.isLoading = true
                        
                        const body = {
                            id: uuidv4(),
                            channelId: this.selectedInputChannel,
                            text: this.formatMessage(e.products),
                            number: e.customer.primaryPhoneNumber
                        };

                        const res = await this.postMessage(body, e.customer.communicatorDefaultChannel, e.customer.id)

                        if(res.status == 200) {
                            e.isSuccess = true
                            await this.putNotifWishlists(e.products.map(e => e.wishlistId))
                        } else {
                            e.isSuccess = false
                        }

                        e.isLoading = false
                    } catch (error) {
                        e.isLoading = false
                        e.isSuccess = false
                        console.log("send-message-batch-error", error)
                    }
                }

                this.isLoadingSend = false
            },

            sendEmailBatch: async function() {
                this.isLoadingSend = true

                for (let i = 0; i < this.selectedFields.length; i++) {
                    const e = this.selectedFields[i];

                    try {
                        e.isLoading = true

                        const data = await this.getListOrderByCustomer(e)

                        if(data.content.length > 0) {
                            const body = {
                                to: e.customer.primaryEmail,
                                subject: "Informasi Produk Wishlist",
                                text: this.formatMessage(e.products)
                            }
    
                            const res = await this.postEmail(body)
    
                            if(res.status == 200) {
                                e.isSuccess = true
                                await this.putNotifWishlists(e.products.map(e => e.wishlistId))
                            } else {
                                e.isSuccess = false
                            }
                        } else {
                            e.haveNoOrder = true
                        }

                        e.isLoading = false
                    } catch (error) {
                        e.isLoading = false
                        e.isSuccess = false
                        console.log("send-message-batch-error", error)
                    }
                }

                this.isLoadingSend = false
            },

            postMessage: async function(body, defaultChannel, contactId) {  
                try {
                    return await axios.post(process.env.VUE_APP_API_URL + '/message/' + contactId + '/' + defaultChannel, body);
                } catch (error) {
                    console.log("post-message-error", error)
                }
            },

            postEmail: async function(body) {
                try {
                    return await axios.post(process.env.VUE_APP_API_URL + `/email/send`, body)
                } catch (error) {
                    console.log("post-email-error", error)
                }
            },

            postReport: async function() {
                try {
                    const { data } = await axios.post(process.env.VUE_APP_API_URL + `/cmrc/wishlist/report?pendingOnly=${this.selectedNotification}`, {})
                    
                    this.queryId = data.queryId 
                } catch (error) {
                    if(error.response) {
                        Toast.showToast("error", error.response.data.message)
                    } else {
                        Toast.showToast("error", error.message)
                    }
                }
            },

            putNotifWishlists: async function(ids) {
                try {
                    const config = Object()
                    config.params = {
                        wishlistIds: ids,
                        isNotificationSent: true
                    }

                    await axios.put(process.env.VUE_APP_API_URL + `/cmrc/wishlist/notif-status`, {}, config)
                } catch (error) {
                    if(error.response) {
                        Toast.showToast("error", error.response.data.message)
                    } else {
                        Toast.showToast("error", error.message)
                    }
                }
            },

            getReportWishlist: async function() {
                try {
                    this.isLoading = true

                    const config = Object()

                    config.params = {
                        term: this.term,
                        page: this.number - 1,
                        size: this.size,
                        queryId: this.queryId
                    }

                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/cmrc/wishlist', config)
              
                    this.wishlists = data.content.map(e => {
                        e.id = e.wishlistId

                        return e
                    })

                    this.number = data.number + 1
                    this.totalPages = data.totalPages
                    this.totalElements = data.totalElements
                    this.selectedFields = Array()

                    this.isLoading = false
                } catch (error) {
                    this.isLoading = false

                    if(error.response) {
                        Toast.showToast("error", error.response.data.message)
                    } else {
                        Toast.showToast("error", error.message)
                    }
                }
            },

            getCustomerById: async function(id) {
                try {
                    return await axios.get(process.env.VUE_APP_API_URL + '/contact/id/' + encodeURIComponent(id));
                } catch (error) {
                    console.log("get-customer-by-id-error", error)
                }
            },

            searchContact: async function () {
                this.isLoadingContact = true

                try {
                    var {
                        data
                    } = await axios.get(process.env.VUE_APP_API_URL + '/contact/', {
                        params: {
                            page: 1,
                            term: this.searchInputContact,
                            size: 25,
                            status: 'ACTIVE',
                        }
                    });

                    this.inputContactList = data.content

                    this.isLoadingContact = false
                } catch (error) {
                    this.isLoadingContact = false
                    console.log("search-input-contact", error);
                }
            },

            searchChannel: async function() {
                this.isLoadingChannel = true

                try {
                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/commchannel/', {
                        params: {
                            page: 1,
                            size: 100
                            // term: this.searchInputChannel,
                        }
                    });

                    this.inputChannelList = data.content
                } catch (error) {
                    console.log("search-input-channel", error);
                }

                this.isLoadingChannel = false
            },

            utcToLocal: function(date, format) {
                var utcLocal = moment(date).local().format(format);
                return utcLocal;
            },

            utcToLocalView: function(date, format) {
                var utcLocal = moment(date).format(format);
                return utcLocal;
            },

            localToUtc: function(date, format) {
                var utcLocal = moment(date).utc().format(format);
                return utcLocal;
            },
        },

        created: function () {
            this.$emit('update:layout', MainLayout);
        },

        mounted: async function () {
            Scrollbar.initAll();
            Scrollbar.get($('#left-inner-scrollbar').get()[0]);

            // var self = this;
            // userScrollBar.addListener((status) => {
            //     if (this.commChannelUIFlag.hasMoreData && status.offset.y == userScrollBar.limit.y) {
            //         setTimeout(() => self.loadMoreUsers(), 500);
            //     }
            // });

            // if (!this.$helpers.isBlank(this.sessionInfo) && this.sessionInfo.isLoggedIn) {
            //     await this.loadInitialData();
            // }

            try {
                mainuisetup();
                commonpagesetup();
            } catch (err) {
                console.log(err);
            }

            await this.initOperatorList()

            // await this.postReport()
            // await this.getReportWishlist()

            await this.searchChannel()
        },

        components: {

        }
    }
</script>